var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-table',{attrs:{"border":"","data":_vm.tableData,"tooltip-effect":"dark","empty-text":"请上传文件","height":_vm.tableHeight}},[[_c('el-table-column',{attrs:{"prop":"fileName","label":"文件名称","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.loading)?[_c('i',{staticClass:"el-icon-loading",staticStyle:{"color":"#f00"}}),_vm._v("正在上传 ")]:[_c('a',{staticStyle:{"text-decoration":"revert","color":"#409eff"},attrs:{"target":"_blank","href":`${_vm.filepath}${scope.row[_vm.fields.path]}`}},[_vm._v(_vm._s(scope.row.fileName))])]]}}])}),_c('el-table-column',{attrs:{"label":"备注","align":"center","show-overflow-tooltip":false},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 2, maxRows: 2 },"disabled":_vm.disabled ||
            (!_vm.options.postAttribute == 'DEPT_MANAGER' &&
              (_vm.Nstatus.status == 0 ||
                _vm.Nstatus.status == 5 ||
                _vm.Nstatus.status == 25 ||
                _vm.Nstatus.status == 35 ||
                _vm.Nstatus.status == 45))},model:{value:(scope.row.fileContent),callback:function ($$v) {_vm.$set(scope.row, "fileContent", $$v)},expression:"scope.row.fileContent"}})]}}])})],_c('el-table-column',{attrs:{"align":"center","width":"80"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return (
        !_vm.disabled ||
        (_vm.options.postAttribute == 'DEPT_MANAGER' &&
          (_vm.options.status == 0 ||
            _vm.options.status == 5 ||
            _vm.options.status == 25 ||
            _vm.options.status == 35 ||
            _vm.options.status == 45))
      )?[_c('Upload',{attrs:{"type":_vm.type,"fields":_vm.fields},on:{"upload":_vm.upload}})]:undefined}},{key:"default",fn:function(scope){return [(
          !_vm.disabled ||
          (_vm.options.postAttribute == 'DEPT_MANAGER' &&
            (_vm.options.status == 0 ||
              _vm.options.status == 5 ||
              _vm.options.status == 25 ||
              _vm.options.status == 35 ||
              _vm.options.status == 45))
        )?_c('i',{staticClass:"iconfont iconerror",on:{"click":function($event){return _vm.del(scope)}}}):_vm._e()]}}],null,true)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }