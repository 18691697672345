<template>
  <el-table
    border
    :data="tableData"
    tooltip-effect="dark"
    empty-text="请上传文件"
    :height="tableHeight"
  >
    <!-- :prop="fields.name"  :prop="fields.path"-->
    <template name="tableColumn">
      <el-table-column prop="fileName" label="文件名称" align="center">
        <template slot-scope="scope">
          <template v-if="scope.row.loading">
            <i class="el-icon-loading" style="color: #f00"></i>正在上传
          </template>
          <template v-else>
            <a
              target="_blank"
              style="text-decoration: revert; color: #409eff"
              :href="`${filepath}${scope.row[fields.path]}`"
              >{{ scope.row.fileName }}</a
            >
          </template>
        </template>
      </el-table-column>
      <el-table-column label="备注" align="center" :show-overflow-tooltip="false">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.fileContent"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 2 }"
            :disabled="
              disabled ||
              (!options.postAttribute == 'DEPT_MANAGER' &&
                (Nstatus.status == 0 ||
                  Nstatus.status == 5 ||
                  Nstatus.status == 25 ||
                  Nstatus.status == 35 ||
                  Nstatus.status == 45))
            "
          ></el-input>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="filePath" label="文件路径" align="center">
        <template slot-scope="scope">
          <template v-if="scope.row.loading">
            <i class="el-icon-loading" style="color:#f00;"></i>正在上传
          </template>
          <template v-else>
            <a
              target="_blank"
              style="text-decoration:revert;color:#409eff"
              :href="`${filepath}${scope.row[fields.path]}`"
              >{{ scope.row[fields.path] }}</a
            >
          </template>
        </template>
      </el-table-column> -->
    </template>
    <!--  v-if="!disabled" -->
    <el-table-column align="center" width="80">
      <template
        slot="header"
        slot-scope="scope"
        v-if="
          !disabled ||
          (options.postAttribute == 'DEPT_MANAGER' &&
            (options.status == 0 ||
              options.status == 5 ||
              options.status == 25 ||
              options.status == 35 ||
              options.status == 45))
        "
      >
        <Upload :type="type" :fields="fields" @upload="upload"></Upload>
      </template>
      <template slot-scope="scope">
        <i
          v-if="
            !disabled ||
            (options.postAttribute == 'DEPT_MANAGER' &&
              (options.status == 0 ||
                options.status == 5 ||
                options.status == 25 ||
                options.status == 35 ||
                options.status == 45))
          "
          @click="del(scope)"
          class="iconfont iconerror"
        ></i>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  components: {
    Upload: () => import('./Upload.vue'),
  },
  props: {
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
    Nstatus: {
      type: Object,
      default: function () {
        return {}
      },
    },
    tableHeight: {
      type: String,
      default: 'string',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Object,
      default: function () {
        return {
          dictName: 'attachmentName',
          path: 'attachmentPath',
          type: 'attachmentType',
        }
      },
    },
    value: {
      type: Array,
      default: function () {
        return []
      },
    },
    type: {
      type: String,
      default: 'default',
    },
  },
  computed: {
    ...mapState({}),
  },
  data() {
    return {
      filepath: process.env.VUE_APP_FILEPATH,
      tableData: [],
      delArr: [],
      addArr: [],
    }
  },
  created() {},
  watch: {
    value: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        this.tableData = newVal
      },
    },
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  methods: {
    upload(r) {
      r.some(res => {
        this.tableData.push(
          res.combination({
            type: this.type,
          })
        )
      })
      this.$emit('change', this.tableData)
      this.$store.commit({
        type: 'project/ADD_PROJECTFILELIST',
        addAgreementFileList: this.tableData,
      })
    },
    del(scope) {
      let arr = this.tableData.splice(scope.$index, 1)
      this.$emit('change', this.tableData)
      this.delArr.push(...arr)
      this.$store.commit({
        type: 'project/DEL_PROJECTFILELIST',
        delAgreementFileList: this.delArr,
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
